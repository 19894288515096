import { useCallback, useState } from "react";

import { useClientLayoutEffect } from "@/util/use-client-layout-effect.mjs";

/**
 * This has to take an element as an argument instead of refs because it needs to be notified
 * when the referenced element changes, and refs don't notify on change.
 */
export function useElementRect(element?: Element | null): DOMRect | null {
  const [rect, setRect] = useState<DOMRect | null>(
    () => element?.getBoundingClientRect() ?? null,
  );

  const updateRect = useCallback(() => {
    if (!element) return;

    // Getting boundingClientRect via IntersectionObserver avoids reflow
    // reflow causes performance hit when it is fired per scroll event, and also breaks animations
    const obs = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === element) {
          if (
            (["top", "right", "bottom", "left", "x", "y"] as const).some(
              (key) => entry.boundingClientRect[key] !== rect?.[key],
            )
          ) {
            setRect(entry.boundingClientRect);
          }

          obs.disconnect();
        }
      }
    });

    obs.observe(element);
  }, [element, rect]);

  useClientLayoutEffect(() => {
    const target = element?.ownerDocument?.defaultView;

    if (!target) return;

    updateRect();

    element.addEventListener("transitionend", updateRect, { passive: true });
    target.addEventListener("resize", updateRect, { passive: true });
    target.addEventListener("scroll", updateRect, { passive: true });

    return () => {
      element.removeEventListener("transitionend", updateRect);
      target.removeEventListener("resize", updateRect);
      target.removeEventListener("scroll", updateRect);
    };
  }, [element]);

  return rect;
}
