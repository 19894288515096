import React, { useCallback } from "react";

import { AddCardDialogView } from "@/feature-wallet/AddCardDialogView.jsx";
import { AddPaymentMethodDialogView } from "@/feature-wallet/AddPaymentMethodDialogView.jsx";
import { AddPaypalDialogView } from "@/feature-wallet/AddPayPalDialogView.jsx";
import { DialogWindow } from "@/shared/Dialog.jsx";
import {
  createModalNavigation,
  useModalNavigation,
} from "@/shared/ModalNavigation.jsx";

const AddPaymentMethodNav = createModalNavigation<{
  addPaymentMethod: void;
  addCard: void;
  addPayPal: void;
}>("purchase-premium");

export function AddPaymentMethodDialog({ closeModal }: { closeModal(): void }) {
  const modalRouter = useModalNavigation(
    AddPaymentMethodNav,
    "addPaymentMethod",
  );

  const onRequestAddCard = useCallback(() => {
    modalRouter.pushRoute("addCard");
  }, [modalRouter]);

  const onRequestAddPayPal = useCallback(() => {
    modalRouter.pushRoute("addPayPal");
  }, [modalRouter]);

  const onAddedPaymentMethod = useCallback(
    (_paymentMethodId: string) => {
      closeModal();
    },
    [closeModal],
  );

  const onBack = useCallback(() => {
    modalRouter.popRoute();
  }, [modalRouter]);

  return (
    <DialogWindow width={400}>
      <AddPaymentMethodNav.Navigator
        modalRouter={modalRouter}
        onPopScope={closeModal}
      >
        <AddPaymentMethodNav.Route name="addPaymentMethod">
          <AddPaymentMethodDialogView
            onRequestAddCard={onRequestAddCard}
            onRequestAddPayPal={onRequestAddPayPal}
          />
        </AddPaymentMethodNav.Route>

        <AddPaymentMethodNav.Route name="addCard">
          <AddCardDialogView
            onAddedPaymentMethod={onAddedPaymentMethod}
            onBack={onBack}
          />
        </AddPaymentMethodNav.Route>

        <AddPaymentMethodNav.Route name="addPayPal">
          <AddPaypalDialogView
            onAddedPaymentMethod={onAddedPaymentMethod}
            onBack={onBack}
          />
        </AddPaymentMethodNav.Route>
      </AddPaymentMethodNav.Navigator>
    </DialogWindow>
  );
}
