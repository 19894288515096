import React, { useCallback, useMemo } from "react";
import { styled } from "goober";
import { tabletSmall } from "clutch/src/Style/style.mjs";

import { postData } from "@/__main__/get-data.mjs";
import noopModel from "@/data-models/no-op.mjs";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import {
  removePaymentMethod,
  updateDefaultPaymentMethod,
} from "@/feature-wallet/actions.mjs";
import { AddPaymentMethodTile } from "@/feature-wallet/AddPaymentMethodTile.jsx";
import {
  postRemovePayMethod,
  postUpdateDefaultPayMethod,
} from "@/feature-wallet/api.mjs";
import type { PaymentMethod } from "@/feature-wallet/models/payment-method.mjs";
import PortfolioCardTile from "@/feature-wallet/PortfolioCardTile.jsx";
import { walletRefs } from "@/feature-wallet/refs.mjs";
import { useDefaultUserPaymentMethod } from "@/feature-wallet/utils/default-user-payment-method.mjs";
import { usePaymentMethods } from "@/feature-wallet/utils/payment-methods.mjs";
import { useActiveSubscriptions } from "@/feature-wallet/utils/subscription-status.mjs";
import { devError } from "@/util/dev.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  Container: styled("div")`
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(315px, 320px));
    grid-gap: var(--sp-4);

    ${tabletSmall} {
      justify-content: center;
    }

    & .add-modal {
      border-radius: var(--br-lg);
      max-width: 100%;
      width: calc(var(--sp-1) * 103.5);

      padding: var(--sp-6);
      background-color: var(--shade7);
    }
  `,
};

export function Portfolio() {
  const refs = useSnapshot(walletRefs);

  const paymentMethods = usePaymentMethods();
  const activeSubscriptions = useActiveSubscriptions();

  const defaultPaymentMethod = useDefaultUserPaymentMethod();

  const activePaymentMethods = useMemo(
    () =>
      activeSubscriptions.reduce(
        (acc, { paymentMethodId, willCancelAtPeriodEnd }) => {
          if (willCancelAtPeriodEnd) return acc;
          acc.add(paymentMethodId);
          return acc;
        },
        new Set<string>(),
      ),
    [activeSubscriptions],
  );

  const onMakeDefault = useCallback(async (paymentMethod: PaymentMethod) => {
    try {
      const bearerToken = await getBearerToken();

      await postData(
        postUpdateDefaultPayMethod({
          paymentMethodId: paymentMethod.id,
          providerType: paymentMethod.providerType,
        }),
        noopModel,
        undefined,
        {
          headers: { Authorization: bearerToken },
        },
      );

      updateDefaultPaymentMethod(paymentMethod.id);
    } catch (e) {
      devError(e);
    }
  }, []);

  const onRemovePaymentMethod = useCallback(
    async (paymentMethod: PaymentMethod) => {
      try {
        const bearerToken = await getBearerToken();

        if (defaultPaymentMethod.id === paymentMethod.id) {
          const newDefaultPaymentMethod = paymentMethods.find(
            (paymentMethod) =>
              !("expiryDate" in paymentMethod) ||
              paymentMethod.expiryDate > new Date(),
          );

          await postData(
            postUpdateDefaultPayMethod({
              paymentMethodId: newDefaultPaymentMethod.id,
              providerType: newDefaultPaymentMethod.providerType,
            }),
            noopModel,
            undefined,
            {
              headers: { Authorization: bearerToken },
            },
          );

          updateDefaultPaymentMethod(newDefaultPaymentMethod.id);
        }

        await postData(
          postRemovePayMethod({
            paymentMethodId: paymentMethod.id,
            providerType: paymentMethod.providerType,
          }),
          noopModel,
          undefined,
          {
            headers: { Authorization: bearerToken },
          },
        );

        removePaymentMethod(paymentMethod.id);
      } catch (e) {
        devError(e);
      }
    },
    [defaultPaymentMethod, paymentMethods],
  );

  return (
    <Styled.Container>
      {refs.currencies?.map((Component, i) => <Component key={i} />)}

      <refs.ProTile />

      {paymentMethods.map((paymentMethod) => (
        <PortfolioCardTile
          key={paymentMethod.id}
          isActive={activePaymentMethods.has(paymentMethod.id)}
          onMakeDefault={() => onMakeDefault(paymentMethod)}
          onRemove={() => onRemovePaymentMethod(paymentMethod)}
          paymentMethod={paymentMethod}
        />
      ))}

      <AddPaymentMethodTile />
    </Styled.Container>
  );
}
