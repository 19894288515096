import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Modal } from "clutch/src/Modal/Modal.jsx";

import { readState } from "@/__main__/app-state.mjs";
import type { UserTransaction } from "@/feature-wallet/models/user-transactions.mjs";
import { PaymentTypeIcon } from "@/feature-wallet/Shared.jsx";
import { getPaymentMethodTranslation } from "@/feature-wallet/utils/payment-method-translation.mjs";
import { usePaymentMethod } from "@/feature-wallet/utils/payment-methods.mjs";
import Close from "@/inline-assets/close.svg";
import PrintIcon from "@/inline-assets/print.svg";
import { formatCurrency, formatDateTime } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  Container: styled("div")`
    --padding: var(--sp-6);
    max-width: 100%;
    width: calc(var(--sp-1) * 149);
    border-radius: var(--br-lg);

    position: relative;
    background-color: var(--shade7);

    @media print {
      height: 10in;
      .actions {
        display: none;
        visibility: hidden;
      }

      --shade0: black;
      --shade1: black;
      color: var(--shade0);
    }

    & .actions {
      position: absolute;
      top: var(--sp-5);
      right: var(--padding);
      display: flex;
      align-items: center;

      & .icon-button {
        cursor: pointer;
        background-color: transparent;
        color: var(--shade3);
        padding: 0;

        &:hover {
          color: var(--shade1);
        }

        &.close {
          svg {
            width: var(--sp-6);
            height: var(--sp-6);
          }
        }
      }
    }

    & .view {
      width: 100%;

      & h3 {
        padding: var(--padding);
      }

      & ul {
        border-top: 1px solid var(--shade6);
        padding: var(--padding);

        li {
          position: relative;
          display: flex;
          justify-content: space-between;
          gap: var(--sp-2);
        }

        & > li:last-of-type {
          border-top: 1px solid var(--shade6);
          padding-top: var(--sp-2);
        }

        ol {
          padding-left: var(--sp-2);
        }
      }
    }
  `,
};

export function ReceiptModal({
  isOpen,
  onClose,
  transaction,
}: {
  isOpen: boolean;
  onClose: () => void;
  transaction: UserTransaction;
}) {
  const modalRef = useRef<HTMLDialogElement>();

  useEffect(() => {
    if (isOpen) {
      modalRef.current?.showModal();
    } else {
      modalRef.current?.close();
    }
  }, [isOpen]);

  return (
    <Modal ref={modalRef} onClose={onClose}>
      {Boolean(transaction) && (
        <ReceiptView transaction={transaction} onClose={onClose} />
      )}
    </Modal>
  );
}

function ReceiptView({
  transaction,
  onClose,
}: {
  onClose: () => void;
  transaction: UserTransaction;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    wallet: { subscriptions },
  } = useSnapshot(readState);
  const receiptRef = useRef();

  const subscriptionMap = useMemo(
    () =>
      subscriptions.reduce((acc, s) => {
        acc[s.id] = s;
        return acc;
      }, {}),
    [subscriptions],
  );

  // TODO: html2canvas seems to be having issues with current versions of chrome/firefox
  // const handleDownload = () => {
  //   if (receiptRef.current) {
  //     html2canvas(receiptRef.current, {
  //       // scale: 2,
  //       // backgroundColor: "hsl(222deg 14% 11%)",
  //     }).then((canvas) => {
  //       canvas.toBlob(function (blob) {
  //         const newImg = globals.document.createElement("img"),
  //           url = URL.createObjectURL(blob),
  //           a = globals.document.createElement("a");
  //         newImg.src = url;
  //         a.href = url;
  //         a.setAttribute(
  //           "download",
  //           `${t("common:wallet.receipt.fileName", "receipt")}.png`
  //         );
  //         a.click();
  //         URL.revokeObjectURL(url);
  //       });
  //     });
  //   }
  // };

  const handlePrint = () => {
    // eslint-disable-next-line no-restricted-properties
    window.print();
  };

  const {
    amountDue,
    createdAt,
    chargedAt,
    items,
    tax,
    currency,
    receiptNumber,
    paymentIntent,
    providerType,
    paymentMethodId,
  } = transaction;

  const paymentMethod = usePaymentMethod({ providerType, paymentMethodId });

  const paymentMethodType =
    providerType === "BRAINTREE"
      ? "paypal_account"
      : (paymentMethod?.type ?? "card");

  return (
    <Styled.Container>
      <div className="actions">
        {/* <button className="icon-button" onClick={handleDownload}>
          <DownloadIcon />
          <span className="visually-hidden">
            {t("common:wallet.receipt.actions.download", "download")}
          </span>
        </button> */}
        <button className="icon-button" onClick={handlePrint}>
          <PrintIcon />
          <span className="visually-hidden">
            {t("common:wallet.receipt.actions.print", "print")}
          </span>
        </button>
        <button className="icon-button close" onClick={onClose}>
          <Close />
          <span className="visually-hidden">
            {t("common:wallet.receipt.actions.close", "close")}
          </span>
        </button>
      </div>
      <div className="view" ref={receiptRef}>
        <h3 className="type-body1-form--active">
          {t("common:wallet.receipt.title", "Purchase Details")}
        </h3>
        <ul className="type-caption shade1 flex column gap-2">
          {receiptNumber !== undefined && (
            <li>
              {t("common:wallet.receipt.id", "Receipt Id:")}
              <span className="shade0">{receiptNumber}</span>
            </li>
          )}
          <li>
            {t("common:wallet.receipt.date", "Payment Date:")}
            <span className="shade0">
              {formatDateTime(language, chargedAt ?? createdAt)}
            </span>
          </li>
          <li>
            {t("common:wallet.receipt.method", "Payment Method:")}
            <span className="shade0">
              <span className="flex gap-2 align-center">
                <PaymentTypeIcon
                  type={paymentMethodType}
                  cardBrand={paymentIntent?.paymentMethodCardBrand}
                />

                {t(
                  ...getPaymentMethodTranslation({
                    type: paymentMethodType,
                    cardLast4: paymentIntent?.paymentMethodCardLast4,
                  }),
                )}
              </span>
            </span>
          </li>
          <li className="flex column gap-2 align-start">
            {t("common:wallet.receipt.items", "Payment Items:")}
            <ol className="flex column gap-2 w-full">
              {items.map(({ subscriptionId, amount, currency }, i) => (
                <li key={i}>
                  {subscriptionMap[subscriptionId]?.planName}
                  <span className="shade0">
                    {formatCurrency(language, amount / 100, currency)}
                  </span>
                </li>
              ))}
            </ol>
          </li>
          <li>
            {t("common:wallet.receipt.tax", "Tax:")}
            <span className="shade0">
              {formatCurrency(language, tax / 100, currency)}
            </span>
          </li>
          <li>
            {t("common:wallet.receipt.total", "Total:")}
            <span className="shade0">
              {formatCurrency(language, amountDue / 100, currency)}
            </span>
          </li>
        </ul>
      </div>
    </Styled.Container>
  );
}
