import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Tag } from "clutch/src/Tag/Tag.jsx";

import { Portfolio } from "@/feature-wallet/Portfolio.jsx";
import { Transactions } from "@/feature-wallet/Transactions.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const Styled = {
  Container: styled("div")`
    margin: 0 auto;
    width: 100%;
    max-width: var(--sp-container);

    display: flex;
    flex-direction: column;
    gap: var(--sp-4);
  `,
};

interface WalletTabs {
  id: string;
  name: Translation;
  Component: React.FC | null;
  disabled?: boolean;
}

const tabs: WalletTabs[] = [
  {
    id: "portfolio",
    name: ["common:wallet.portfolio", "Portfolio"],
    Component: Portfolio,
  },
  {
    id: "transactions",
    name: ["common:wallet.transactions", "Transactions"],
    Component: Transactions,
  },
  {
    id: "inventory",
    name: ["common:wallet.inventory", "Inventory"],
    Component: null,
    disabled: true,
  },
];

function Wallet() {
  const { t } = useTranslation();
  const {
    parameters: [tabId],
  } = useRoute();

  const currentTab = tabs.find(
    ({ id, disabled }) => id === tabId && disabled !== true,
  );

  const { Component } = currentTab;

  return (
    <Styled.Container>
      <PageHeader
        title={t("common:wallet.yourWallet", "Your Wallet")}
        links={tabs.map(({ id, name, disabled }) => ({
          url: `/wallet/${id}`,
          disabled,

          ...(disabled
            ? {
                Text: <DisabledTab text={name} />,
              }
            : { text: name }),
        }))}
      />
      {Component && <Component />}
    </Styled.Container>
  );
}

function DisabledTab({ text }: { text: Translation | string }) {
  const { t } = useTranslation();
  return (
    <span className="flex align-center gap-2">
      {typeof text === "string" ? text : t(...text)}
      <Tag
        color="var(--red)"
        text={t("common:comingSoonNormal", "Coming Soon")}
      />
    </span>
  );
}

export function meta() {
  return {
    title: ["common:wallet.wallet", "Wallet"],
    description: [],
  };
}

export default Wallet;
