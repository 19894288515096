import React, { useContext, useMemo, useState } from "react";

import { devError } from "@/util/dev.mjs";

type MenuContextValue = {
  open?: boolean;
  setOpen: (open: boolean | ((open: boolean) => boolean)) => void;
};

const MenuContext = React.createContext<MenuContextValue>({
  open: false,
  setOpen: () => devError("MenuContext not initialized"),
});

const MenuContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const value = useMemo(
    () => ({
      open,
      setOpen,
    }),
    [open, setOpen],
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export default MenuContextProvider;
export const useMenuContextProvider = () => useContext(MenuContext);
