import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";
import { Menu } from "clutch/src/Menu/Menu.jsx";

import { readState } from "@/__main__/app-state.mjs";
import type { UserSubscription } from "@/feature-wallet/models/user-subscriptions.mjs";
import { ReceiptModal } from "@/feature-wallet/ReceiptModal.jsx";
import { PaymentTypeIcon } from "@/feature-wallet/Shared.jsx";
import { getPaymentMethodTranslation } from "@/feature-wallet/utils/payment-method-translation.mjs";
import { getPaymentMethod } from "@/feature-wallet/utils/payment-methods.mjs";
import BlitzInfo from "@/inline-assets/blitz-info-border.svg";
import NavMoreIcon from "@/inline-assets/nav-more.svg";
import SendFeedback from "@/inline-assets/send-feedback-border.svg";
import { formatCurrency, formatDateTime } from "@/util/i18n-helper.mjs";
import { useIsLoaded } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  Container: styled("div")`
    width: 100%;
    border-radius: var(--br-lg);
    padding: var(--sp-6);
    overflow-x: auto;

    background-color: var(--shade7);

    & .center-text {
      text-align: center;
    }

    & table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        text-align: left;
        padding: var(--sp-2) var(--sp-3);
      }

      & tbody tr td .status {
        color: var(--shade1);
        &.succeeded {
          color: var(--turq);
        }

        &.failed {
          color: var(--red);
        }
      }

      & tbody tr:not(:last-of-type) {
        border-bottom: 1px solid var(--shade6);
      }
    }
  `,
};

export function Transactions() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    wallet: { transactions, subscriptions, paymentProviders },
  } = useSnapshot(readState);
  const isLoaded = useIsLoaded();
  const [selectedTxn, setSelectedTxn] = useState(null);

  const subscriptionMap = useMemo(
    () =>
      subscriptions.reduce<Record<string, UserSubscription>>((acc, s) => {
        acc[s.id] = s;
        return acc;
      }, {}),
    [subscriptions],
  );

  if (!transactions.length && !isLoaded) return <LoadingSpinner />;

  if (!transactions.length && isLoaded)
    return (
      <Styled.Container>
        <h5 className="type-h5 shade1 center-text">
          {t("wallet:noTransactions", "No transactions found")}
        </h5>
      </Styled.Container>
    );

  return (
    <Styled.Container>
      <table>
        <thead>
          <tr className="type-caption--semi shade1">
            <th>{t("common:wallet.transactionsTable.date", "Date")}</th>
            <th>
              {t("common:wallet.transactionsTable.description", "Description")}
            </th>
            <th>
              {t("common:wallet.transactionsTable.payment", "Payment Method")}
            </th>
            <th>{t("common:wallet.transactionsTable.amount", "Amount")}</th>
            <th>{t("common:wallet.transactionsTable.status", "Status")}</th>
            <th>
              <span className="visually-hidden">
                {t("common:wallet.transactionsTable.actions", "actions")}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((txn, i) => {
            const {
              amountDue,
              createdAt,
              chargedAt,
              currency,
              items,
              paymentIntent,
              providerType,
              paymentMethodId,
              subscriptionId,
            } = txn;

            // `subscriptionId` was added to the root of the transaction object in a later release (i.e. PayPal),
            // but it was previously nested in the `items` array. This is a temporary fix to handle both cases
            // until the old data is no longer cached in the backend.
            const subscription =
              subscriptionMap[subscriptionId ?? items?.[0]?.subscriptionId];

            const paymentMethod = getPaymentMethod({
              paymentProviders,
              providerType: providerType,
              paymentMethodId: paymentMethodId,
            });

            const paymentMethodType =
              providerType === "BRAINTREE"
                ? "paypal_account"
                : (paymentMethod?.type ?? "card");

            return (
              <tr key={i} className="type-form--button">
                <td>{formatDateTime(language, chargedAt ?? createdAt)}</td>
                <td>{subscription?.planName ?? "---"}</td>
                <td>
                  <span className="flex gap-2 align-center">
                    <PaymentTypeIcon
                      type={paymentMethodType}
                      cardBrand={paymentIntent?.paymentMethodCardBrand}
                    />

                    {t(
                      ...getPaymentMethodTranslation({
                        type: paymentMethodType,
                        cardLast4: paymentIntent?.paymentMethodCardLast4,
                      }),
                    )}
                  </span>
                </td>
                <td>{formatCurrency(language, amountDue / 100, currency)}</td>
                <td>
                  <TransactionStatus {...txn} />
                </td>
                <td>
                  <Menu trigger={<NavMoreIcon />}>
                    <Menu.Item
                      icon={<BlitzInfo />}
                      text={["common:wallet.viewDetails", "View Receipt"]}
                      onClick={() => setSelectedTxn(txn)}
                    />
                    <Menu.Item
                      icon={<SendFeedback />}
                      text={[
                        "common:wallet.reportAProblem",
                        "Report a problem",
                      ]}
                      href="mailto:support@blitz.gg?subject=Plan/Billing Issue"
                    />
                  </Menu>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ReceiptModal
        isOpen={Boolean(selectedTxn)}
        onClose={() => setSelectedTxn(null)}
        transaction={selectedTxn}
      />
    </Styled.Container>
  );
}

function TransactionStatus({
  isAttempted,
  isPaid,
}: {
  isAttempted: boolean;
  isPaid: boolean;
}) {
  const { t } = useTranslation();

  if (isPaid)
    return (
      <span className="status succeeded">
        {t("common:wallet.transactionStatus.succeeded", "Paid")}
      </span>
    );

  if (isAttempted && !isPaid)
    return (
      <span className="status failed">
        {t("common:wallet.transactionStatus.failed", "Failed")}
      </span>
    );

  return (
    <span className="status">
      {t("common:wallet.transactionStatus.pending", "Pending")}
    </span>
  );
}
