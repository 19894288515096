import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { AddPaymentMethodDialog } from "@/feature-wallet/AddPaymentMethodDialog.jsx";
import { PortfolioTile } from "@/feature-wallet/PortfolioTile.jsx";
import Add from "@/inline-assets/add.svg";
import FullscreenOverlay from "@/shared/FullscreenOverlay.jsx";

export function AddPaymentMethodTile() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <PortfolioTile className="has-action" onClick={openModal}>
        <div className="action">
          <div className="wrapper">
            <Add />
          </div>
        </div>
        <div className="title type-body1">
          {t("common:wallet.addPaymentMethod", "Add Payment Method")}
        </div>
      </PortfolioTile>

      <FullscreenOverlay isOpen={isModalOpen} onModalClose={closeModal}>
        <AddPaymentMethodDialog closeModal={closeModal} />
      </FullscreenOverlay>
    </>
  );
}
