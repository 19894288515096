import React from "react";
import { useTranslation } from "react-i18next";
import { Menu } from "clutch/src/Menu/Menu.jsx";
import { Tag } from "clutch/src/Tag/Tag.jsx";

import { showRouteErrorNotification } from "@/app/actions.mjs";
import type { PaymentMethod } from "@/feature-wallet/models/payment-method.mjs";
import { PortfolioTile } from "@/feature-wallet/PortfolioTile.jsx";
import { PaymentTypeIcon } from "@/feature-wallet/Shared.jsx";
import { useDefaultUserPaymentMethod } from "@/feature-wallet/utils/default-user-payment-method.mjs";
import { getPaymentMethodTranslation } from "@/feature-wallet/utils/payment-method-translation.mjs";
import DontNeed from "@/inline-assets/circle-close.svg";
import NavMoreIcon from "@/inline-assets/nav-more.svg";
import Settings from "@/inline-assets/settings.svg";
import { classNames } from "@/util/class-names.mjs";

interface PortfolioCardTileProps {
  isActive: boolean;
  onMakeDefault: () => void;
  onRemove: () => void;

  paymentMethod: PaymentMethod;
}

export default function PortfolioCardTile({
  isActive,
  onMakeDefault,
  onRemove,
  paymentMethod,
}: PortfolioCardTileProps) {
  const { t } = useTranslation();
  const isExpired =
    "expiryDate" in paymentMethod && paymentMethod.expiryDate < new Date();

  const handleRemove = () => {
    if (isActive) {
      showRouteErrorNotification(
        t(
          "common:wallet.error.removeActiveCard",
          "You cannot remove this card as it is being used in an active subscription.",
        ),
        "warning",
      );

      return;
    }

    onRemove();
  };

  const defaultPaymentMethod = useDefaultUserPaymentMethod();

  return (
    <PortfolioTile>
      <div className="action">
        <PaymentTypeIcon {...paymentMethod} />
      </div>
      <div className="sub-actions flex gap-2">
        {paymentMethod.id === defaultPaymentMethod?.id && (
          <Tag
            className="tag"
            color="var(--turq)"
            text={t("common:default", "Default")}
          />
        )}
        {isActive && (
          <Tag
            className="tag"
            color="var(--subscriber-solid)"
            text={t("common:subscriber", "Premium")}
          />
        )}
        <div>
          <Menu trigger={<NavMoreIcon />}>
            {paymentMethod.id !== defaultPaymentMethod?.id && (
              <Menu.Item
                icon={<Settings />}
                text={[
                  "common:wallet.makeDefault",
                  "Make Default Payment Method",
                ]}
                onClick={onMakeDefault}
              />
            )}
            {!isActive && (
              <Menu.Item
                icon={<DontNeed />}
                text={["common:wallet.removeCard", "Remove Card"]}
                onClick={handleRemove}
              />
            )}
          </Menu>
        </div>
      </div>
      <div className="title flex column">
        <span {...classNames("type-body1 shade0", isExpired && "expired")}>
          {t(...getPaymentMethodTranslation(paymentMethod))}
        </span>
        {"expiryDate" in paymentMethod && (
          <span
            {...classNames(
              "type-form--button shade1 subtitle",
              isExpired && "expired",
            )}
          >
            {isExpired
              ? t("common:wallet.expired", "Expired")
              : t(
                  "common:wallet.expiresDate",
                  "Expires {{expiryDate, datetime}}",
                  {
                    expiryDate: paymentMethod.expiryDate,
                    formatParams: { month: "numeric", year: "2-digit" },
                  },
                )}
          </span>
        )}
      </div>
    </PortfolioTile>
  );
}
